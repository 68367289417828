import React from 'react'
import './sidebar.css'
import {FaFacebookSquare, FaTwitterSquare, FaPinterestSquare, FaInstagramSquare} from 'react-icons/fa'
function SideBar() {
  return (
    <div className='sideBar'>
        <div className="sideBarItem">
          <span className='sideBarTittle'>ABOUT ME</span>
          <p >
            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
          </p>
        </div>
        <div className='sideBarItem'>
          <span className='sideBarTittle'>CATEGORIES</span>
          <ul className='sideBarList'>
            <li className="sideBarListItem">Life</li>
            <li className="sideBarListItem">Music</li>
            <li className="sideBarListItem">Style</li>
            <li className="sideBarListItem">Sport</li>
            <li className="sideBarListItem">Tech</li>
            <li className="sideBarListItem">Cinema</li>
          </ul>
        </div>
        <div>
          <div className='sideBarItem'>
            <span className='sideBarTittle'>FOLLOW US</span>
            <div className='sideBarSocial'>
            <FaFacebookSquare className='sideBarIcon'/>
            <FaTwitterSquare className='sideBarIcon'/>
            <FaPinterestSquare className='sideBarIcon'/>
            <FaInstagramSquare className='sideBarIcon'/>
            </div>
          </div>
        </div>

    </div>
  )
}
export default SideBar
