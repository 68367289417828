import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home/Home.jsx';
import TopBar from './components/TopBar/TopBar.jsx';
import Settings from './Pages/settings/Settings.jsx';
import Posts from './components/posts/Posts.jsx';
import './App.css';

function App() {
  return (
    <Router>
      <TopBar />
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/settings" element={<Settings/>} />
        <Route path="/Posts" element={<Posts/>} />

        {/* Add other routes as needed */}
      </Routes>
    </Router>
  );
}

export default App;
