import React from 'react';
import './home.css';
import  Header from '../components/Header/Header';
import Sidebar from '../components/Sidebar/SideBar';
import Posts from '../components/posts/Posts.jsx';


function Home() {
  return (
    <div className='home'>
        
            <Header className='home-Header' />
          <div className='home-Body'>
            <div className ='left-Home'> 
              <Posts/>
            </div>
            
            <div className='right-Home'> 
            
              <Sidebar/>
            </div>
          </div>
    </div>
    
  )
}

export default Home
