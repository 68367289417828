import React from 'react';
import './posts.css';
import testImage from './testImage.webp';

function Posts() {
    const posts = [
        {
            id: 1,
            title: 'First Blog Post',
            content: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.',
            image: testImage,
            date:'1 hour ago'
        },
        {
            id: 2,
            title: 'Second Blog Post',
            content: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.',
            image: testImage,
            date:'1 hour ago'
        },
        {
            id: 3,
            title: 'Third Blog Post',
            content: 'It is a long established fact that a reader readable content of a page when looking at its layout..',
            image: testImage,
            date:'1 hour ago'
        },
        {
            id: 4,
            title: 'Fourth Blog Post',
            content: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout..',
            image: testImage,
            date:'1 hour ago'
        },
        {
            id: 5,
            title: 'Fifth Blog Post',
            content: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout..',
            image: testImage,
            date:'1 hour ago'
        }
        // ... and so on
    ];

    return (
        <div className='posts'>
            {posts.map(post => (
                <div key={post.id} className='post'>
                    <img src={post.image} alt={post.title} className='post-Image'/>
                    <h2 className='postTIttle'>{post.title}</h2>
                    <p className='postContent'>{post.content}</p>
                    <p className='postDate'>{post.date}</p>
                </div>
            ))}
        </div>
    );
}

export default Posts;
