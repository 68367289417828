import React from 'react'
import './settings.css'
import {FaUserCircle} from 'react-icons/fa'


function Settings() {
  return (
    <div className='settings'>
        <div className="settingsWrapper">
            <div className='settingsTittle'>
                <span className='settingsUpdateTittle'>Update Your Account</span>
                <span className='settingsDeleteTittle'>Delete Your Account</span>
            </div>
            <form className='settingsForm'>
                <label>Profile picture </label>
                <div className='settingsprofilePicture'>
                    <img src='' alt=''/>
                    <label htmlFor='fileInput'>
                    <FaUserCircle className='userIcon'/>
                    </label>
                    <input type="file" id='fileInput' style={{display:'none'}}/>
                    <label>Username</label>
                    <input type="text" placeholder='celine' />
                    <label>Email</label>
                    <input type="email" placeholder='celine@gmail,com' />

                </div>
            </form>
        </div>
    </div>
  )
}

export default Settings