import React from 'react'
import './TopBar.css'
import cuteImage from './image/cute.jpg'

function TopBar() {
  return (
    <div className='top'>
        <div className="topleft">
        <i className="topIcon fa-brands fa-square-facebook"></i>
        <i className="topIcon fa-brands fa-square-instagram"></i>
        <i className="topIcon fa-brands fa-square-pinterest"></i>
        <i className="topIcon fa-brands fa-square-twitter"></i>
        </div> 
        <div className="topcentre"> 
          <ul className='topList'>
            <li className='topListItem'>HOME</li>
            <li className='topListItem'>ABOUT</li>
            <li className='topListItem'>CONTACT</li>
            <li className='topListItem'>WRITE</li>
            <li className='topListItem'>LOGOUT</li>
          </ul>
        </div>
        <div className="topright">
          <img className='topImg ' src={cuteImage} alt="cuteimage"/>
          <i className="topSearchIcon fas fa-search"></i>
          </div> 
    </div>
  ) 
}

export default TopBar