import React from 'react'; // Ensure React is imported if it's not done elsewhere
import './header.css';
import AloneImage from './image/Alone.png';

function Header() {
    return (
        <div className="header">
            <div className="headerTittles">
                <span className="headerTittleSm">React & Node</span>
                <span className="headerTittleLg">Blog</span>
                <img className="headerImg" src={AloneImage} alt="sunimage"/>
            </div>
        </div>
    );
}

export default Header;
